import { Capacitor } from "@capacitor/core";
import { Network } from "@capacitor/network";
import { SplashScreen } from "@capacitor/splash-screen";
import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
// services
import { Storage } from "./services/storage";
import { migrateNativeDataFromXamarin } from "./services/native-data-migration";
import { storeAppGroupKey } from "./services/gatewayPreferences";
import { enableCrashlytics } from "./services/crashlytics";
// types
import type { Root } from "react-dom/client";

type Importer = () => Promise<Module>;
type Module = {
	renderApp: (root: Root, error?: Error | null) => void;
};

export function registerServiceWorker() {
	if (process.env.BUILD_MODE !== "development") {
		// register service worker to prefetch assets if runtime/browser supports it
		// this also caches all assets for offline usage
		if ("serviceWorker" in navigator) {
			window.addEventListener("load", async () => {
				try {
					await navigator.serviceWorker.register("sw.js");
				} catch (error) {
					console.error("SW registration failed:", error);
				}
			});
		}
	}
}

export async function render(root: Root, importer: Importer, error: Error | null = null) {
	try {
		const { renderApp } = await importer();
		renderApp(root, error);
	} catch (error) {
		// the app could not be rendered
		// so error gets rendered "manually" as last fallback
		const msg = error?.message ?? "unknown error";
		document.body.innerHTML = msg;
	}
}

export async function renderIfAuthenticated(root: Root, importer: Importer, firstRun = false) {
	try {
		if (firstRun) {
			if (Capacitor.isNativePlatform()) {
				try {
					const oldConfig = await BackgroundGeolocation.getState();
					await BackgroundGeolocation.ready(oldConfig);
				} catch (error) {
					console.warn("renderIfAuthenticated, BackgroundGeolocation.ready failed", error);
				}
			}
			await Network.addListener("networkStatusChange", async () => {
				await renderIfAuthenticated(root, importer);
			});
		}

		const { connected } = await Network.getStatus();
		if (connected) {
			try {
				if (firstRun) {
					Storage.migrate();
					if (Capacitor.isNativePlatform()) {
						await enableCrashlytics();
						await migrateNativeDataFromXamarin();
						await storeAppGroupKey();
					}
				}
				await render(root, importer);
			} catch (error) {
				console.error(error);
				window.setTimeout(async () => {
					await renderIfAuthenticated(root, importer);
				}, 15000);
				await render(root, importer, error);
			}
		} else {
			await render(root, importer, new Error("Network offline"));
			await SplashScreen.hide();
		}
	} catch (error) {
		console.error("failed to get network status", error);
	}
}
